@charset "UTF-8";
/* ====================== IMPORTS ====================== */
/*------------------------------------*    RESET
\*------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0b1 | 201101 
    NOTE:WORK IN PROGRESS
    USE WITH CAUTION AND TEST WITH ABANDON */
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: ’’;
  content: none; }

/* remember to define visible focus styles! 
:focus{
    outline:?????;
} */
/* remember to highlight inserts somehow! */
ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* CSS Document */
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0; }

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto; }

.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

a img.alignnone {
  margin: 5px 20px 20px 0; }

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center; }

.wp-caption.alignnone {
  margin: 5px 20px 20px 0; }

.wp-caption.alignleft {
  margin: 5px 20px 20px 0; }

.wp-caption.alignright {
  margin: 5px 0 20px 20px; }

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto; }

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px; }

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */ }

/* ====================== VARIABLES ====================== */
/* ====================== GENERAL STYLES ====================== */
body {
  font-family: "Raleway", Arial, sans-serif;
  font-size: 10px;
  background-image: url("/wp-content/themes/emersonRealtyManagement/assets/images/sayagata-400px.png");
  background-repeat: repeat; }
  body p {
    line-height: 1.45em; }
  body a {
    text-decoration: none;
    transition: all .3s ease-in-out;
    color: #ff6100; }
    body a:hover {
      text-decoration: underline; }

main {
  flex-grow: 1; }

/* ====================== FORM STYLES ====================== */
form label,
.nf-form-wrap label {
  text-transform: uppercase;
  color: grey; }

form input,
form select,
form textarea,
.nf-form-wrap input,
.nf-form-wrap select,
.nf-form-wrap textarea {
  border: 2px solid #ff6100;
  height: 42px; }

form .nf-field-element input,
form select,
form textarea,
.nf-form-wrap .nf-field-element input,
.nf-form-wrap select,
.nf-form-wrap textarea {
  border: 2px solid #ff6100;
  height: 42px; }

form .label-above .nf-field-label,
.nf-form-wrap .label-above .nf-field-label {
  margin-bottom: 5px; }

form .nf-before-form-content,
.nf-form-wrap .nf-before-form-content {
  margin: 10px 0; }

form input[type='button'], form input[type='submit'],
.nf-form-wrap input[type='button'],
.nf-form-wrap input[type='submit'] {
  background-color: #ff6100;
  text-transform: uppercase;
  color: white;
  font-size: 1.5em;
  transition: all .3s ease;
  min-width: 125px; }
  form input[type='button']:hover, form input[type='submit']:hover,
  .nf-form-wrap input[type='button']:hover,
  .nf-form-wrap input[type='submit']:hover {
    background-color: #ff9a5c;
    cursor: pointer; }

/* ====================== HEADER STYLES ====================== */
header .wrapper {
  display: flex;
  background-color: white;
  height: 75px; }
  header .wrapper a {
    width: 210px; }
    header .wrapper a img {
      width: 100%; }
  header .wrapper nav {
    flex-grow: 1;
    max-width: 80%;
    margin-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    header .wrapper nav ul {
      display: flex;
      justify-content: flex-end; }
      header .wrapper nav ul li {
        padding: 0 20px; }
        header .wrapper nav ul li a {
          text-decoration: none;
          color: black;
          text-transform: uppercase;
          font-size: 1.25vw;
          font-weight: 700;
          transition: all .3s ease; }
          header .wrapper nav ul li a:hover {
            border-bottom: 1px solid #ff6100;
            width: 100%;
            color: #ff6100; }

.titleImg {
  height: 25vw;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

/* ====================== FRONT PAGE STYLES ====================== */
article {
  margin: 50px 0; }
  article .heroSection-Container {
    display: flex; }
  article .heroImg-Container {
    width: 75%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 30vw; }
  article .quickNav-Container {
    flex-grow: 1; }
    article .quickNav-Container .menu-quick-navigation-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%; }
      article .quickNav-Container .menu-quick-navigation-container ul {
        display: flex;
        flex-direction: column;
        flex-grow: 1; }
        article .quickNav-Container .menu-quick-navigation-container ul li {
          display: flex;
          justify-content: center;
          flex-grow: 1;
          background-color: #ff6100; }
          article .quickNav-Container .menu-quick-navigation-container ul li a {
            text-transform: uppercase;
            text-decoration: none;
            color: white;
            font-size: 2vw;
            background-color: #ff6100;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            background-position: 1000px 1000px;
            transition: margin .3s ease; }
          article .quickNav-Container .menu-quick-navigation-container ul li:nth-child(2) {
            background-color: #ff7d2e; }
            article .quickNav-Container .menu-quick-navigation-container ul li:nth-child(2) a {
              background-color: #ff7d2e; }
          article .quickNav-Container .menu-quick-navigation-container ul li:nth-child(3) {
            background-color: #ff9a5c; }
            article .quickNav-Container .menu-quick-navigation-container ul li:nth-child(3) a {
              background-color: #ff9a5c; }
  article .introduction-Container {
    width: 80%;
    margin: 0 auto;
    color: #8d8d8d; }
    article .introduction-Container h1 {
      font-size: 4em;
      text-align: center;
      font-weight: 700;
      text-transform: uppercase; }
    article .introduction-Container p {
      font-size: 2em;
      margin: 20px 0; }
  article .subPageIntroduction {
    margin-top: -95px;
    margin-bottom: 100px;
    position: relative;
    z-index: 1000;
    max-width: 1280px; }

.applicationContainer {
  background-color: white;
  padding: 25px 0; }

.searchRow-Container {
  display: flex; }
  .searchRow-Container .searchFields-Container {
    width: 66%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    padding: 40px; }
    .searchRow-Container .searchFields-Container .descriptionTitle-Container {
      width: 100%;
      display: flex;
      justify-content: center; }
      .searchRow-Container .searchFields-Container .descriptionTitle-Container h1 {
        font-size: 2.5em;
        font-weight: 700;
        text-transform: uppercase;
        color: white; }
    .searchRow-Container .searchFields-Container .searchBodyContent-Container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .searchRow-Container .searchFields-Container .searchBodyContent-Container .inputFields {
        margin: 1em 0; }
      .searchRow-Container .searchFields-Container .searchBodyContent-Container label {
        display: block;
        margin-bottom: 5px;
        font-size: 1.5em;
        text-transform: uppercase;
        color: white; }
      .searchRow-Container .searchFields-Container .searchBodyContent-Container select {
        height: 2.5em;
        border-radius: 5px;
        border: none;
        padding: .5em;
        width: 100%; }
      .searchRow-Container .searchFields-Container .searchBodyContent-Container span {
        font-size: 4em;
        color: white;
        font-weight: 300; }
      .searchRow-Container .searchFields-Container .searchBodyContent-Container .alternateOption-Container a {
        font-size: 3em;
        text-transform: uppercase;
        text-decoration: none;
        color: #ff6100; }
  .searchRow-Container .searchBtn-Container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .searchRow-Container .searchBtn-Container input[type=submit] {
      background-color: #ff6100;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 110%;
      font-size: 2em;
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      font-weight: 700;
      padding: 0 25px;
      z-index: 999;
      transition: all .15s ease;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .searchRow-Container .searchBtn-Container input[type=submit]:hover {
        background-color: #ff9a5c;
        cursor: pointer; }
  .searchRow-Container .subLogo-Container {
    width: 25%;
    min-width: 250px;
    background-color: #8d8d8d;
    z-index: -999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .searchRow-Container .subLogo-Container h1 {
      text-transform: uppercase;
      color: white;
      font-size: 4em;
      font-weight: 700; }
    .searchRow-Container .subLogo-Container span {
      color: white;
      text-transform: uppercase;
      font-size: 1.5em; }

/* ====================== SUB PAGE STYLES ====================== */
.subPageIntroduction {
  background-color: white;
  padding: 20px 30px;
  box-shadow: 0px 8px 10px 0px #888888; }
  .subPageIntroduction .csz-Container {
    display: flex;
    justify-content: center;
    font-size: 3em;
    text-transform: uppercase;
    margin-top: 10px; }

.listingsContainer {
  margin: 40px 0; }

.subPageBackground-Container {
  background-color: white; }

.subPageMainContentContainer {
  width: 80%;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 0; }
  .subPageMainContentContainer .twoColumn {
    width: 45%;
    margin: 0 20px;
    min-width: 475px; }
    .subPageMainContentContainer .twoColumn .applyNow-Container {
      display: flex; }
      .subPageMainContentContainer .twoColumn .applyNow-Container a {
        background-color: #ff6100;
        color: white;
        text-transform: uppercase;
        padding: 10px;
        font-size: 2em;
        font-weight: 700;
        text-decoration: none;
        flex-grow: 1;
        text-align: center;
        transition: all .3s ease; }
        .subPageMainContentContainer .twoColumn .applyNow-Container a:hover {
          background-color: #8d8d8d; }
    .subPageMainContentContainer .twoColumn iframe {
      margin: 40px 0; }
    .subPageMainContentContainer .twoColumn p {
      font-size: 2em;
      margin-bottom: 1em;
      line-height: 1.5; }
    .subPageMainContentContainer .twoColumn .sub-field-container h2 {
      font-size: 3.5em;
      text-transform: uppercase;
      color: #ff6100;
      font-weight: 700;
      margin-bottom: 20px; }
    .subPageMainContentContainer .twoColumn .sub-field-container .sub-fields-content {
      margin: 20px 0;
      font-size: 1.25em; }
      .subPageMainContentContainer .twoColumn .sub-field-container .sub-fields-content > span {
        color: #889696;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.5em; }
      .subPageMainContentContainer .twoColumn .sub-field-container .sub-fields-content p {
        margin: 10px 0; }

/* ====================== UA UNIT LIST STYLES ====================== */
.ua-Container {
  width: 80%;
  margin: 0 auto;
  max-width: 1280px;
  min-height: 150px; }
  .ua-Container .individualListing-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    height: 150px;
    max-width: 1000px; }
    .ua-Container .individualListing-Container .listingImg-Container {
      width: 33%;
      height: 100%;
      background-position: center;
      background-size: cover; }
    .ua-Container .individualListing-Container .listingDetails-Container {
      background-color: white;
      flex-grow: 1;
      padding: 10px; }
      .ua-Container .individualListing-Container .listingDetails-Container h1 {
        font-size: 2em;
        font-weight: 700;
        text-transform: uppercase;
        margin: 5px 0; }
      .ua-Container .individualListing-Container .listingDetails-Container > span {
        font-size: 2em; }
      .ua-Container .individualListing-Container .listingDetails-Container .listingSpecs-Container {
        margin-top: 20px;
        font-size: 2em;
        font-weight: 700;
        display: flex; }
        .ua-Container .individualListing-Container .listingDetails-Container .listingSpecs-Container div {
          margin-right: 10px;
          margin-bottom: 10px; }
        .ua-Container .individualListing-Container .listingDetails-Container .listingSpecs-Container span {
          font-weight: 400; }
    .ua-Container .individualListing-Container .listingSubmit-Container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .ua-Container .individualListing-Container .listingSubmit-Container a {
        text-decoration: none;
        background-color: #ff6100;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px;
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        transition: all .3s ease; }
        .ua-Container .individualListing-Container .listingSubmit-Container a:hover {
          background-color: #ff9a5c;
          cursor: pointer; }
  .ua-Container .uaListHeadline-Container {
    width: 100%; }
    .ua-Container .uaListHeadline-Container h1 {
      text-align: center;
      font-size: 3em;
      text-transform: uppercase;
      font-weight: 700; }

/* ====================== UA UNIT DETAIL STYLES ====================== */
.communitySpecs-Container {
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  max-width: 95%;
  margin-top: 40px;
  flex-wrap: wrap; }
  .communitySpecs-Container > div {
    color: #ff6100;
    font-weight: 700;
    font-size: 1.75em;
    text-transform: uppercase;
    margin: 10px; }
    .communitySpecs-Container > div span {
      font-weight: 400;
      color: #8d8d8d; }
  .communitySpecs-Container .email {
    text-transform: none; }
    .communitySpecs-Container .email a {
      color: #8d8d8d;
      transition: all .3s ease; }
      .communitySpecs-Container .email a:hover {
        color: #ff6100; }

.communityDetails-Container .communityDetails {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: center; }
  .communityDetails-Container .communityDetails .image-Container .primaryImage-Container .primaryImage {
    min-height: 250px;
    background-size: cover;
    background-position: center bottom; }
    .communityDetails-Container .communityDetails .image-Container .primaryImage-Container .primaryImage a {
      display: block;
      width: 100%;
      height: 100%; }
  .communityDetails-Container .communityDetails .image-Container .thumbnailImage-Container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .communityDetails-Container .communityDetails .image-Container .thumbnailImage-Container .thumbnailImage {
      background-size: cover;
      background-position: center bottom;
      min-height: 100px;
      width: 33%;
      margin: .25em 0; }
      .communityDetails-Container .communityDetails .image-Container .thumbnailImage-Container .thumbnailImage a {
        display: block;
        width: 100%;
        height: 100%; }
  .communityDetails-Container .communityDetails .features-Container {
    margin: 40px 0; }
    .communityDetails-Container .communityDetails .features-Container h2 {
      color: #8d8d8d;
      font-size: 4em;
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: uppercase;
      text-align: center; }
    .communityDetails-Container .communityDetails .features-Container ul {
      margin: 0;
      font-size: 2em; }
    .communityDetails-Container .communityDetails .features-Container ul {
      list-style-type: none; }
    .communityDetails-Container .communityDetails .features-Container ul > li {
      text-indent: -5px;
      margin: 5px 0; }
    .communityDetails-Container .communityDetails .features-Container ul > li:before {
      content: "-";
      text-indent: -5px; }

.communityDetails-Container .availableUnits-Container {
  width: 100%; }

/* ====================== FOOTER STYLES ====================== */
footer {
  font-size: 2em;
  position: relative;
  margin-top: 50px; }
  footer .footerMenu-Container ul {
    display: flex;
    justify-content: center;
    margin-bottom: -20px; }
    footer .footerMenu-Container ul li {
      background-color: #ff7d2e;
      padding: 10px 0;
      transition: all .3s ease;
      z-index: 999; }
      footer .footerMenu-Container ul li:hover {
        background-color: #ff9a5c; }
      footer .footerMenu-Container ul li a {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0 20px; }
  footer .footerSection {
    padding: 20px 0;
    display: flex;
    align-items: center;
    background-color: #889696;
    color: white; }
    footer .footerSection .footerContent-Container {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; }
      footer .footerSection .footerContent-Container span {
        font-weight: 700;
        text-transform: uppercase; }
    footer .footerSection .builtBy-Container a {
      color: #ff7d2e;
      text-decoration: none;
      text-transform: uppercase;
      border-bottom: 2px solid #889696;
      transition: all .3s ease; }
      footer .footerSection .builtBy-Container a:hover {
        border-bottom: 2px solid #ff9a5c;
        color: #ff9a5c; }

/* ====================== FORM FIELDS ====================== */
form {
  width: 80%;
  margin: 0 auto; }
  form p {
    font-size: 1.75em; }
  form ul {
    font-size: 1.75em;
    line-height: 2;
    list-style-type: disc;
    list-style-position: inside; }
  form fieldset {
    margin: 40px 0; }
    form fieldset legend {
      font-size: 2em;
      text-transform: uppercase;
      border-bottom: 5px solid #ff6100;
      width: 100%;
      padding-bottom: 5px; }
    form fieldset .fieldContainer {
      display: flex;
      flex-wrap: wrap; }
      form fieldset .fieldContainer .applicantReferencesContainer {
        display: flex;
        width: 100%; }
      form fieldset .fieldContainer .formField {
        margin: 20px 10px;
        margin-bottom: 20px; }
        form fieldset .fieldContainer .formField label {
          display: block;
          display: block;
          margin-bottom: 5px;
          font-size: 1.5em; }
        form fieldset .fieldContainer .formField input,
        form fieldset .fieldContainer .formField select {
          height: 30px; }
        form fieldset .fieldContainer .formField .error {
          margin-top: 5px;
          font-size: 1.25em;
          color: red; }
        form fieldset .fieldContainer .formField .requiredAsterix {
          color: red;
          font-size: 1.25em;
          margin-right: 2.5px; }
    form fieldset .formCheckbox {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      form fieldset .formCheckbox label {
        color: black;
        font-size: 1.25em;
        line-height: 1.5; }
      form fieldset .formCheckbox input {
        height: 15px;
        width: 15px;
        flex-shrink: 0; }

/* ====================== MOBILE STYLES ====================== */
@media (max-width: 1024px) {
  article .heroSection-Container {
    display: block; }
    article .heroSection-Container .heroImg-Container {
      width: 100%;
      min-height: 50vw; }
    article .heroSection-Container .quickNav-Container {
      flex-grow: 1; }
      article .heroSection-Container .quickNav-Container ul li {
        height: 75px; }
        article .heroSection-Container .quickNav-Container ul li a {
          font-size: 200%; }
  .ua-Container {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .ua-Container .individualListing-Container {
      width: 48%;
      max-width: 500px;
      min-width: 315px;
      margin: 50px auto;
      height: 100%;
      flex-wrap: wrap;
      flex-basis: 100%; }
      .ua-Container .individualListing-Container .listingImg-Container {
        width: 100%;
        min-height: 250px; }
      .ua-Container .individualListing-Container .listingDetails-Container {
        width: 100%;
        box-sizing: border-box;
        font-size: .85em; }
        .ua-Container .individualListing-Container .listingDetails-Container .listingSpecs-Container {
          display: flex;
          flex-wrap: wrap;
          margin: 10px 0; }
      .ua-Container .individualListing-Container .listingSubmit-Container {
        width: 100%;
        height: 0;
        align-items: initial;
        display: block; }
        .ua-Container .individualListing-Container .listingSubmit-Container a {
          height: 25%;
          padding: 25px 0; }
  .searchRow-Container {
    flex-wrap: wrap; }
    .searchRow-Container .searchFields-Container {
      text-align: center;
      width: 100%; }
      .searchRow-Container .searchFields-Container .searchBodyContent-Container {
        display: flex;
        flex-direction: column; }
        .searchRow-Container .searchFields-Container .searchBodyContent-Container .searchRow {
          display: flex; }
          .searchRow-Container .searchFields-Container .searchBodyContent-Container .searchRow .inputFields {
            margin: 1em; }
          .searchRow-Container .searchFields-Container .searchBodyContent-Container .searchRow span {
            margin-top: 20px; }
          .searchRow-Container .searchFields-Container .searchBodyContent-Container .searchRow .alternateOption-Container {
            margin-top: 20px; }
    .searchRow-Container .searchBtn-Container {
      width: 100%; }
      .searchRow-Container .searchBtn-Container input[type=submit] {
        width: 100%;
        padding: 20px 0; }
    .searchRow-Container .subLogo-Container {
      min-width: 0;
      width: 100%;
      padding: 20px 0; }
  .communityDetails-Container .communityDetails {
    flex-wrap: wrap; }
    .communityDetails-Container .communityDetails .twoColumn {
      width: 100%;
      min-width: auto; }
  footer .footerSection .footerContent-Container {
    font-size: 60%;
    justify-content: center;
    display: flex;
    flex-direction: column; } }

@media (max-width: 650px) {
  .ua-Container .individualListing-Contianer {
    width: 100%;
    min-width: 0; }
  .subPageMainContentContainer {
    width: 95%; }
    .subPageMainContentContainer .twoColumn {
      width: 100%;
      font-size: .85em;
      min-width: 0; } }
