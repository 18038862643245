/* ====================== IMPORTS ====================== */

@import 'cssReset';
@import 'wordPressCore';
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');

/* ====================== VARIABLES ====================== */

$font-stack: 'Raleway',
Arial,
sans-serif;
$primary-color: #ff6100;
$secondary-color: #8d8d8d;
$accent-orange-dark: #ff7d2e;
$accent-orange-light: #ff9a5c;
$slate-grey: #889696;

/* ====================== GENERAL STYLES ====================== */

body {
  font-family: $font-stack;
  font-size: 10px;
  background-image: url('/wp-content/themes/emersonRealtyManagement/assets/images/sayagata-400px.png');
  background-repeat: repeat;
//  display: -webkit-box;      
//  display: -moz-box;         
//  display: -ms-flexbox;      
//  display: -webkit-flex;     
//  display: flex;  
//  min-height: 100vh;
//  flex-direction: column;
  p {
    line-height: 1.45em;
    //margin: 40px 0;
  }
  a{
    text-decoration:none;
    transition: all .3s ease-in-out;
    color:$primary-color;
    &:hover{
     text-decoration:underline;
    }
  }
}
main {
  flex-grow: 1;
}

/* ====================== FORM STYLES ====================== */

form,
.nf-form-wrap {
  label {
    text-transform: uppercase;
    color: grey;
  }
  input,
  select,
  textarea {
    border: 2px solid $primary-color;
    height: 42px;
//    -webkit-appearance: none;
//    -moz-appearance: none;
  }
  .nf-field-element input,
  select,
  textarea {
    border: 2px solid $primary-color;
    height: 42px;
  }
  .label-above .nf-field-label {
    margin-bottom: 5px;
  }
  .nf-before-form-content {
    margin: 10px 0;
  }
  input[type='button'], input[type='submit'] {
    background-color: $primary-color;
    text-transform: uppercase;
    color: white;
    font-size: 1.5em;
    transition: all .3s ease;
    min-width: 125px;
    &:hover {
      background-color: $accent-orange-light;
      cursor: pointer;
    }
  }
}

/* ====================== HEADER STYLES ====================== */

header {
  .wrapper {
    display: flex;
    background-color: white;
    height: 75px;
    a {
      width: 210px;
      img {
        width: 100%;
      }
    }
    nav {
      flex-grow: 1;
      max-width: 80%;
      margin-right: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      ul {
        display: flex;
        justify-content: flex-end;
        li {
          padding: 0 20px;
          a {
            text-decoration: none;
            color: black;
            text-transform: uppercase;
            font-size: 1.25vw;
            font-weight: 700;
            transition: all .3s ease;
            &:hover {
              border-bottom: 1px solid $primary-color;
              width: 100%;
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
.titleImg {
  height: 25vw;
  max-height:500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* ====================== FRONT PAGE STYLES ====================== */

article {
  margin: 50px 0;
  .heroSection-Container {
    display: flex;
  }
  .heroImg-Container {
    width: 75%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 30vw;
  }
  .quickNav-Container {
    flex-grow: 1;
    .menu-quick-navigation-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      ul {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        li {
          display: flex;
          justify-content: center;
          flex-grow: 1;
          background-color: $primary-color;
          a {
            text-transform: uppercase;
            text-decoration: none;
            color: white;
            font-size: 2vw;
            background-color: #ff6100;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            background-position: 1000px 1000px;
            transition: margin .3s ease;
          }
          &:nth-child(2) {
            background-color: $accent-orange-dark;
            a {
              background-color: $accent-orange-dark;
            }
          }
          &:nth-child(3) {
            background-color: $accent-orange-light;
            a {
              background-color: $accent-orange-light;
            }
          }
        }
      }
    }
  }
  .introduction-Container {
    width: 80%;
    margin: 0 auto;
    color: $secondary-color;
    h1 {
      font-size: 4em;
      text-align: center;
      font-weight: 700;
      text-transform: uppercase;
    }
    p {
      font-size: 2em;
      margin: 20px 0;
    }
  }
  .subPageIntroduction {
    margin-top: -95px;
    margin-bottom: 100px;
    position: relative;
    z-index: 1000;
    max-width: 1280px;
//    min-height: 100px;
  }
}
.applicationContainer{
    background-color:white;
    padding:25px 0;
  }
.searchRow-Container {
  display: flex;
  .searchFields-Container {
    width: 66%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    .descriptionTitle-Container {
      width: 100%;
      display: flex;
      justify-content: center;
      h1 {
        font-size: 2.5em;
        font-weight: 700;
        text-transform: uppercase;
        color: white;
      }
    }
    .searchBodyContent-Container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .inputFields {
        margin: 1em 0;
      }
      label {
        display: block;
        margin-bottom: 5px;
        font-size: 1.5em;
        text-transform: uppercase;
        color: white;
      }
      select {
        height: 2.5em;
        border-radius: 5px;
        border: none;
        padding: .5em;
        width: 100%;
      }
      span {
        font-size: 4em;
        color: white;
        font-weight: 300;
      }
      .alternateOption-Container {
        a {
          font-size: 3em;
          text-transform: uppercase;
          text-decoration: none;
          color: $primary-color;
          &:hover {}
        }
      }
    }
    //		.searchBtn-Container{
    //			width: 8%;
    //			display:flex;
    //			justify-content: center;
    //			align-items:center;
    //			a{
    //				background-color: #ff6100;
    //				display: flex;
    //				justify-content: center;
    //				align-items: center;
    //				height: 110%;
    //				width: 100%;
    //				font-size: 2em;
    //				text-transform: uppercase;
    //				text-decoration: none;
    //				color: white;
    //				font-weight: 700;
    //				padding: 0 10px;
    //				z-index: 999;
    //				transition: all .15s ease;
    //				&:hover{
    //					background-color: $accent-orange-light;
    //				}
    //			}	
    //		}
  }
  .searchBtn-Container {
    //width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    input[type=submit] {
      background-color: #ff6100;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 110%;
      //				width: 100%; 
      font-size: 2em;
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      font-weight: 700;
      padding: 0 25px;
      z-index: 999;
      transition: all .15s ease;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      &:hover {
        background-color: $accent-orange-light;
        cursor: pointer;
      }
    }
  }
  .subLogo-Container {
    width: 25%;
    min-width: 250px;
    background-color: $secondary-color;
    z-index: -999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      text-transform: uppercase;
      color: white;
      font-size: 4em;
      font-weight: 700;
    }
    span {
      color: white;
      text-transform: uppercase;
      font-size: 1.5em;
    }
  }
}

/* ====================== SUB PAGE STYLES ====================== */

.subPageIntroduction {
  background-color: white;
  padding: 20px 30px;
  box-shadow: 0px 8px 10px 0px #888888;
  .csz-Container {
    display: flex;
    justify-content: center;
    font-size: 3em;
    text-transform: uppercase;
    margin-top: 10px;
  }
}
.listingsContainer {
  margin: 40px 0;
}
.subPageBackground-Container{
  background-color:white;
}
.subPageMainContentContainer {
  width: 80%;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 0;
  .twoColumn {
    width: 45%;
    margin: 0 20px;
    min-width: 475px;
    //		max-width: 750px;
    .applyNow-Container {
      display: flex;
      a {
        background-color: #ff6100;
        color: white;
        text-transform: uppercase;
        padding: 10px;
        font-size: 2em;
        font-weight: 700;
        text-decoration: none;
        flex-grow: 1;
        text-align: center;
        transition: all .3s ease;
        &:hover { 
          background-color: $secondary-color;
        }
      }
    }
    iframe {
      margin: 40px 0;
    }
    p {
      font-size: 2em;
      margin-bottom:1em;
      line-height:1.5;
    }
    .sub-field-container {
      h2 {
        font-size: 3.5em;
        text-transform: uppercase;
        color: $primary-color;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .sub-fields-content {
        margin: 20px 0;
        font-size: 1.25em;
        >span {
          color: $slate-grey;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.5em;
        }
        p {
          margin: 10px 0;
        }
      }
    }
  }
}

/* ====================== UA UNIT LIST STYLES ====================== */

.ua-Container {
  width: 80%;
  margin: 0 auto;
  max-width:1280px;
  min-height: 150px;
  .individualListing-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    height: 150px;
    max-width: 1000px;
//    display:block;
//    width: 100%;
//    display: flex;
//    width: 100%;
//    height: 200px;
//    margin: 50px 0;

    .listingImg-Container {
      width: 33%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    .listingDetails-Container {
      background-color: white;
      flex-grow: 1;
//      height: 75%;
      padding: 10px;
      h1 {
        font-size: 2em;
        font-weight: 700;
        text-transform: uppercase;
        margin: 5px 0;
      }
      >span {
        font-size: 2em;
      }
      .listingSpecs-Container {
        margin-top: 20px;
        font-size: 2em;
        font-weight: 700;
        display: flex;
        div {
          margin-right: 10px;
          margin-bottom: 10px;
        }
        span {
          font-weight: 400;
        }
      }
    }
    .listingSubmit-Container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        text-decoration: none;
        background-color: $primary-color;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px;
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        transition: all .3s ease;
        &:hover {
          background-color: $accent-orange-light;
          cursor: pointer;
        }
      }
    }
  }
  .uaListHeadline-Container {
    width:100%;
    h1 {
      text-align:center;
      font-size: 3em;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

/* ====================== UA UNIT DETAIL STYLES ====================== */

.communitySpecs-Container {
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  max-width: 95%;
  margin-top: 40px;
  flex-wrap: wrap;
  >div {
    color: $primary-color;
    font-weight: 700;
    font-size: 1.75em;
    text-transform: uppercase;
    margin: 10px;
    span {
      font-weight: 400;
      color: $secondary-color;
    }
  }
  .email {
    text-transform: none;
    a {
      color: $secondary-color;
      transition: all .3s ease;
      &:hover {
        color: $primary-color;
      }
    }
  }
}
.communityDetails-Container {
  .communityDetails {
    display: flex;
    //      flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    .image-Container {
      .primaryImage-Container {
        .primaryImage {
          min-height: 250px;
          background-size: cover;
          background-position: center bottom;
          a{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .thumbnailImage-Container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .thumbnailImage {
          background-size: cover;
          background-position: center bottom;
          min-height: 100px;
          width: 33%;
          margin: .25em 0;
          a{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .features-Container {
      margin: 40px 0;
      h2 {
        color: $secondary-color;
        font-size: 4em;
        font-weight: 700;
        margin-bottom: 20px;
        text-transform: uppercase;
        text-align: center;
      }
      ul {
        margin: 0;
        font-size: 2em;
      }
      ul {
        list-style-type: none;
      }
      ul > li {
        text-indent: -5px;
        margin: 5px 0;
      }
      ul > li:before {
        content: "-";
        text-indent: -5px;
      }
    }
  }
  .availableUnits-Container {
    width: 100%;
  }
}

/* ====================== FOOTER STYLES ====================== */

footer {
  font-size: 2em;
  position: relative;
  margin-top: 50px;
  .footerMenu-Container {
    ul {
      display: flex;
      justify-content: center;
      margin-bottom: -20px;
      li {
        background-color: $accent-orange-dark;
        padding: 10px 0;
        transition: all .3s ease;
        z-index: 999;
        &:hover {
          background-color: $accent-orange-light;
        }
        a {
          color: white;
          text-decoration: none;
          text-transform: uppercase;
          padding: 0 20px;
        }
      }
    }
  }
  .footerSection {
    //    height: 125px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    background-color: $slate-grey;
    color: white;
    .footerContent-Container {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      span {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .builtBy-Container {
      a {
        color: $accent-orange-dark;
        text-decoration: none;
        text-transform: uppercase;
        border-bottom: 2px solid $slate-grey;
        transition: all .3s ease;
        &:hover {
          border-bottom: 2px solid $accent-orange-light;
          color: $accent-orange-light
        }
      }
    }
  }
}

/* ====================== FORM FIELDS ====================== */

form {
  width: 80%;
  margin: 0 auto;
  p {
    font-size: 1.75em;
  }
  ul {
    font-size: 1.75em;
    line-height: 2;
    list-style-type: disc;
    list-style-position: inside;
  }
  fieldset {
    margin: 40px 0;
    legend {
      font-size: 2em;
      text-transform: uppercase;
      border-bottom: 5px solid #ff6100;
      width: 100%;
      padding-bottom: 5px;
    }
    .fieldContainer {
      display: flex;
      flex-wrap: wrap;
      .applicantReferencesContainer {
        display: flex;
        width: 100%;
      }
      .formField {
        margin: 20px 10px;
        margin-bottom: 20px;
        label {
          display: block;
          display: block;
          margin-bottom: 5px;
          font-size: 1.5em;
        }
        input,
        select {
          height: 30px;
        }
        .error {
          margin-top: 5px;
          font-size: 1.25em;
          color: red;
        }
        .requiredAsterix {
          color: red;
          font-size: 1.25em;
          margin-right: 2.5px;
        }
      }
    }
    .formCheckbox {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      label {
        color: black;
        font-size: 1.25em;
        line-height: 1.5;
      }
      input {
        height: 15px;
        width: 15px;
        flex-shrink: 0;
      }
    }
  }
}

/* ====================== MOBILE STYLES ====================== */

@media (max-width: 1024px) {
  article .heroSection-Container {
//    display: flex;
//    flex-direction: column;
    display:block;
    .heroImg-Container {
      width: 100%;
      min-height: 50vw;
    }
    .quickNav-Container {
      flex-grow: 1;
      ul li {
        height: 75px;
        a {
          font-size: 200%;
        }
      }
    }
  }
  .ua-Container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .individualListing-Container {
//      width: 50%;
//      max-width: 500px;
//      min-width: 290px;
//      height: auto;
//      flex-direction: column;
        width: 48%;
        max-width: 500px;
        min-width: 315px;
        margin: 50px auto;
        height:100%;
        flex-wrap: wrap;
        flex-basis:100%;
      .listingImg-Container {
        width: 100%;
        min-height: 250px;
      }
      .listingDetails-Container {
        width: 100%;
        box-sizing: border-box;
        font-size: .85em;
        .listingSpecs-Container {
          display: flex;
          flex-wrap: wrap;
          margin: 10px 0;
        }
      }
      .listingSubmit-Container {
        width: 100%;
        height: 0;
        align-items: initial;
        display: block;
        a {
          height: 25%;
          padding: 25px 0;
        }
      }
    }
  }
  .searchRow-Container {
    flex-wrap: wrap;
    .searchFields-Container {
      text-align:center;
      width: 100%;
      .searchBodyContent-Container {
        display: flex;
        flex-direction: column;
        .searchRow {
          display: flex;
          .inputFields {
            margin: 1em;
          }
          span {
            margin-top: 20px;
          }
          .alternateOption-Container {
            margin-top: 20px;
          }
        }
      }
    }
    .searchBtn-Container {
      width: 100%;
      input[type=submit] {
        width: 100%;
        padding: 20px 0;
      }
    }
    .subLogo-Container {
      min-width: 0;
      width: 100%;
      padding: 20px 0;
    }
  }
  .communityDetails-Container {
    .communityDetails {
      flex-wrap: wrap;
      .twoColumn {
        width: 100%;
        min-width: auto;
      }
    }
  }
  footer {
    .footerSection {
      .footerContent-Container {
        font-size: 60%;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
@media (max-width: 650px) {
  .ua-Container {
    .individualListing-Contianer {
      width: 100%;
      min-width: 0;
    }
  }
  .subPageMainContentContainer{
    width: 95%;
    .twoColumn{
      width: 100%;
      font-size: .85em;
      min-width:0;
    }
  }
}
//@media (max-width: 600px) {
//  
//  .ua-Container{
//    .individualListing-Container{
//      .listingDetails-Container{
//        .listingSpecs-Container{
//          display:flex;
//          flex-direction: column;
//        }
//      }  
//    }
//  }   
//}
